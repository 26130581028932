import * as React from 'react';
import { Container } from '../layout/Container';
import { Section } from '../layout/Section';
import { BackgroundLoad } from '../layout/BackgroundLoad';
import { GlobalSearch } from '../ui/search/cluster/Index';
import { useFadeTransition } from '../../utils/hooks/useFadeTransition';
import { Seo } from '../../utils/seo/Index';
import { RouteComponentProps } from 'react-router';

const GlobalSearchPage: React.FC<RouteComponentProps<any>> = ({ location }) => {
  const { fadeIn } = useFadeTransition({
    immidiate: true
  });
  return (
    <>
      <Seo
        title="PrivatMegleren - Nordeas Eiendomsmeglerkjede"
        description="Søk blant våre eiendommer til salgs"
        image="https://cdn.reeltime.no/pm_assets/img/bakgrunner/pmfront.jpg"
        imageTitle="/PrivatMegleren-eiendomsmegler-kjøp-salg-eiendom-leilighet-hus-bolig"
        url={location.pathname}
      />
      <Container style={{ paddingTop: '100px', minHeight: '1000px' }}>
        <Section style={fadeIn}>
          <GlobalSearch type="global" />
        </Section>
        <BackgroundLoad
          opacity={0.4}
          position="center -300px"
          url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
        />
      </Container>
    </>
  );
};

export default GlobalSearchPage;
